import Button from '@uikit/components/Button/Button';
import { ButtonAlign, ButtonColor } from '@uikit/components/Button/consts';
import { ClickableAction } from '@uikit/components/Clickable/consts';
import {
  HeadlineAlign,
  HeadlineColor,
  HeadlineLevel,
} from '@uikit/components/Headline/consts';
import Headline from '@uikit/components/Headline/Headline';
import Spacer from '@uikit/components/Spacer/Spacer';
import React from 'react';
import { EngCalorificValueCalculatorStep } from '../interfaces';

const EngCalorificValueCalculatorResult = ({
  onSubmit,
  globalFormState,
}: EngCalorificValueCalculatorStep) => {
  return (
    <div>
      {globalFormState.calorificValue ? (
        <>
          <p>Für {globalFormState.city} wurde folgender Brennwert ermittelt:</p>
          <Headline
            align={HeadlineAlign.Left}
            level={HeadlineLevel.Three}
            headlineColor={HeadlineColor.Dark}
          >
            {`${globalFormState.calorificValue
              ?.toPrecision(5)
              .replace('.', ',')} kWh/m&sup3`}
          </Headline>
        </>
      ) : (
        <p>
          Für {globalFormState.city} konnte im angegebenen Zeitraum kein
          Brennwert ermittelt werden
        </p>
      )}
      <Spacer defaultSize={1.5} md={3} />
      <Button
        actionType={ClickableAction.Custom}
        onClick={onSubmit}
        align={ButtonAlign.Center}
        color={ButtonColor.Secondary}
      >
        Angaben ändern
      </Button>
    </div>
  );
};

export default EngCalorificValueCalculatorResult;
