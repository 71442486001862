import React from 'react';
import type { EngStageBoxProps } from './interfaces';
import styles from './EngStageBox.module.scss';
import clsx from 'clsx';
import StageBox from '../StageBox/StageBox';

const EngStageBox = ({
  backgroundType = 'gradient',
  bgClassName,
  children,
}: EngStageBoxProps) => {
  const className = clsx(
    styles.base,
    backgroundType === 'gradient' ? styles.gradient : undefined,
    bgClassName
  );

  return <StageBox bgClassName={className}>{children}</StageBox>;
};

export default EngStageBox;
