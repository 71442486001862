import Background from '../Background/Background';
import NewFooter from '../NewFooter/NewFooter';
import ProjectLogo from '../ProjectLogo/ProjectLogo';
import { EngFooterProps } from './types';

const EngFooter = ({ skewOverlap }: EngFooterProps) => {
  return (
    <NewFooter
      backgroundSlot={<Background.Gradient gradient="meinHGas" />}
      copyrightText="enercity Netz GmbH"
      foundationSlot={
        <>
          <NewFooter.Link href="/impressum">Impressum</NewFooter.Link>
          <NewFooter.Link href="/datenschutz">Datenschutz</NewFooter.Link>
          <NewFooter.Link href="/seitenuebersicht">
            Seitenübersicht
          </NewFooter.Link>
        </>
      }
      logoSlot={<ProjectLogo height={80} project="eng" solidFill />}
      skewOverlap={skewOverlap}
    >
      <NewFooter.LinkDirectory>
        <NewFooter.LinkDirectoryGroup title="Zähler">
          <NewFooter.Link href="/zaehler/zaehlerstand">
            Zählerstand melden
          </NewFooter.Link>
          <NewFooter.Link href="/zaehler/zaehlerwechsel">
            Zählerwechsel
          </NewFooter.Link>
          <NewFooter.Link href="/zaehler/zaehlerservices">
            Zählerservices
          </NewFooter.Link>
          <NewFooter.Link href="/zaehler/brennwertermittlung">
            Brennwertermittlung Gas
          </NewFooter.Link>
        </NewFooter.LinkDirectoryGroup>

        <NewFooter.LinkDirectoryGroup title="Anschließen">
          <NewFooter.Link href="/anschliessen/netzanschluss">
            Netzanschluss
          </NewFooter.Link>
          <NewFooter.Link href="/anschliessen/ladesaeule">
            Ladesäule
          </NewFooter.Link>
          <NewFooter.Link href="/anschliessen/installateur-suchen">
            Installateur suchen
          </NewFooter.Link>
          <NewFooter.Link href="/anschliessen/leitungsnetzauskunft">
            Leitungsnetzauskunft
          </NewFooter.Link>
          <NewFooter.Link href="/anschliessen/kraftwerkneuanschluss">
            Kraftwerkneuanschluss
          </NewFooter.Link>
        </NewFooter.LinkDirectoryGroup>

        <NewFooter.LinkDirectoryGroup title="Partner">
          <NewFooter.Link href="/partner/installateure">
            Installateure
          </NewFooter.Link>
          <NewFooter.Link href="/partner/lieferanten">
            Lieferanten
          </NewFooter.Link>
          <NewFooter.Link href="/partner/netznutzungskunden">
            Netznutzungskunden
          </NewFooter.Link>
          <NewFooter.Link href="/partner/messstellenbetreiber">
            Messstellenbetreiber
          </NewFooter.Link>
        </NewFooter.LinkDirectoryGroup>

        <NewFooter.LinkDirectoryGroup title="Über uns">
          <NewFooter.Link href="/ueber-uns/unternehmen">
            Unternehmen
          </NewFooter.Link>
          <NewFooter.Link href="/ueber-uns/karriere">Karriere</NewFooter.Link>
          <NewFooter.Link href="/ueber-uns/presse">Presse</NewFooter.Link>
          <NewFooter.Link href="/ueber-uns/netzinformationen">
            Netzinformationen
          </NewFooter.Link>
        </NewFooter.LinkDirectoryGroup>

        <div>
          <NewFooter.Link href="/einspeisen" isDirectoryTitle>
            Einspeisen
          </NewFooter.Link>
        </div>
      </NewFooter.LinkDirectory>
    </NewFooter>
  );
};

export default EngFooter;
