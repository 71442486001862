import * as Yup from 'yup';
const schema = Yup.object({
  city: Yup.string().nullable().required('Bitte geben Sie Ihre Gemeinde an.'),

  district: Yup.object({
    value: Yup.string().required('Bitte geben Sie Ihren Stadtteil an.'),
  })
    .nullable()
    .required('Bitte geben Sie Ihren Stadtteil an.'),

  customerType: Yup.string().required('Bitte geben Sie Ihren Kundentyp an.'),

  reason: Yup.string().when('customerType', {
    is: (customerType?: string) => customerType === 'slp',
    then: () => Yup.string().required('Bitte geben Sie einen Anlass an.'),
  }),

  startDate: Yup.string().when('customerType', {
    is: (customerType?: string) => customerType === 'slp',
    then: () =>
      Yup.string().required('Bitte geben Sie ein Abrechnungsbeginn an.'),
  }),

  endDate: Yup.string().when('customerType', {
    is: (customerType?: string) => customerType === 'slp',
    then: () =>
      Yup.string().required('Bitte geben Sie ein Abrechnungsende ein'),
  }),

  periodYear: Yup.object().when('customerType', {
    is: (customerType?: string) => customerType === 'rlm',
    then: () =>
      Yup.object({
        value: Yup.string().required(),
      }).required('Bitte geben Sie ein Jahr für den Abrechnungszeitraum an.'),
  }),
  periodMonth: Yup.object().when('customerType', {
    is: (customerType?: string) => customerType === 'rlm',
    then: () =>
      Yup.object({
        value: Yup.string().required(),
      }).required(
        'Bitte geben Sie einen Monat für den Abrechnungszeitraum an.'
      ),
  }),
});
export default schema;
