import { ButtonSubmitState } from '@uikit/components/ButtonSubmit/consts';
import React from 'react';
import { animated, useTransition } from '@react-spring/web';
import { UseFormState } from '../../../hooks/useForm/interfaces';
import { EngCalorificValueCalculatorSteps } from './config';
import { BoxWidth } from '../Box/consts';
import Box from '../Box/Box';
import EngHeadline from '../EngHeadline/EngHeadline';
import {
  HeadlineAlign,
  HeadlineColor,
  HeadlineLevel,
} from '../Headline/consts';
import Spacer from '../Spacer/Spacer';
import {
  City,
  EngCalorificValueCalculatorProps,
  EngCalorificValueCalculatorState,
} from './interfaces';
import { getCalorificValue, getRegions } from './helpers';

const EngCalorificValueCalculator = ({
  modalHeadline,
  modalText,
}: EngCalorificValueCalculatorProps) => {
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0);
  const [formState, setFormState] = React.useState<UseFormState>({});
  const [submissionState] = React.useState(ButtonSubmitState.Initial);
  const [citys, setCitys] = React.useState<City[]>([]);

  const fillCityFieldOptions = async () => {
    setCitys(await getRegions());
  };

  React.useEffect(() => {
    fillCityFieldOptions();
  }, []);

  const goToFirstStep = async () => {
    setCurrentStepIndex(0);
  };

  const isLastStep = React.useMemo(
    () => EngCalorificValueCalculatorSteps.length - 1 === currentStepIndex,
    [currentStepIndex]
  );

  const handleOnSubmit = async (stepState: UseFormState) => {
    const newFormState: EngCalorificValueCalculatorState = {
      ...formState,
      ...stepState,
    };
    newFormState.calorificValue = await getCalorificValue(stepState);
    if (isLastStep) {
      setFormState(() => newFormState);
      await goToFirstStep();
    } else {
      setCurrentStepIndex((prevState) => prevState + 1);
      setFormState(() => newFormState);
    }
  };

  const step = React.useMemo(
    () => EngCalorificValueCalculatorSteps[currentStepIndex],
    [currentStepIndex]
  );

  const transitions = useTransition(step, {
    from: {
      position: 'absolute',
      opacity: 0,
    },
    enter: { position: 'relative', opacity: 1 },
    leave: { position: 'absolute', opacity: 0 },
  });

  return (
    <Box width={BoxWidth.EngCalorificValueCalculator}>
      <EngHeadline
        align={HeadlineAlign.Left}
        level={HeadlineLevel.Two}
        headlineColor={HeadlineColor.Gradient}
      >
        Brennwert ermitteln
      </EngHeadline>
      <Spacer defaultSize={1.5} md={3} />
      {transitions(({ opacity, position }, Step) => (
        <animated.div
          style={{
            position: position as React.CSSProperties['position'] | any,
            opacity,
          }}
        >
          <Step
            onSubmit={handleOnSubmit}
            submissionState={submissionState}
            globalFormState={formState}
            cities={citys}
            modalText={modalText}
            modalHeadline={modalHeadline}
          />
        </animated.div>
      ))}
    </Box>
  );
};

export default EngCalorificValueCalculator;
