import Container from '@uikit/components/Container/Container';
import EngHeader from '@uikit/components/EngHeader/EngHeader';
import EngHeadline from '@uikit/components/EngHeadline/EngHeadline';
import EngFooter from '@uikit/components/EngFooter/EngFooter';
import EngStageBoxText from '@uikit/components/EngStageBoxText/EngStageBoxText';
import Section from '@uikit/components/Section/Section';
import SectionVisual from '@uikit/components/SectionVisual/SectionVisual';
import TwoColumnContainer from '@uikit/components/TwoColumnContainer/TwoColumnContainer';
import HeroArea from '@uikit/composites/HeroArea/HeroArea';
import SmallHeroArea from '@uikit/composites/SmallHeroArea/SmallHeroArea';
import React from 'react';
import HeroAreaContent from '@uikit/composites/HeroArea/HeroAreaContent';
import EngModal from '@uikit/components/EngModal/EngModal';
import IframeSection from '@uikit/components/IframeSection/IframeSection';
import EngCalorificValueCalculator from '@uikit/components/EngCalorificValueCalculator/EngCalorificValueCalculator';

export const CmsComponentsEng = {
  'eng-footer': EngFooter,
  'eng-header': EngHeader,
  'eng-stage-box-text': EngStageBoxText,
  'eng-section': Section,
  'eng-container': Container,
  'eng-stage-small': SmallHeroArea,
  'eng-hero-area-content': HeroAreaContent,
  'eng-stage': HeroArea,
  'eng-headline': EngHeadline,
  'eng-stage-area': React.Fragment,
  'eng-section-visual': SectionVisual,
  'eng-two-column-container': TwoColumnContainer,
  'eng-modal': EngModal,
  'eng-iframe-section': IframeSection,
  'eng-calorific-value': EngCalorificValueCalculator,
};
