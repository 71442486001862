import { enercityEndpoints } from '@/config/endpoints';
import { getJson } from '@/helpers/ajax';
import { formatDate, FormatDateType, safelyParsedDate } from '@/helpers/date';
import { logger } from '@/helpers/logger';
import { FormFieldDropdownOption } from '../FormFieldDropdown/interfaces';
import { SuggestionOption } from '../FormFieldSuggestion/interfaces';
import { selectableYearsInPast } from './config';
import {
  EngCalorificValueCalculatorStateForm,
  City,
  EngCalorificValueCalculatorAnwser,
} from './interfaces';

export async function getCalorificValue({
  customerType,
  periodMonth,
  periodYear,
  startDate,
  endDate,
  reason,
  district,
}: EngCalorificValueCalculatorStateForm): Promise<number | undefined> {
  if (!(district?.area && customerType)) {
    return undefined;
  }

  const urlPath = `${enercityEndpoints.calorificValue}/value/${district.area}/${customerType}`;

  const rlmDate =
    periodMonth && periodYear
      ? `${periodYear.value}${periodMonth.value}`
      : undefined;

  const start = startDate
    ? formatDate(safelyParsedDate(startDate), FormatDateType.CalorificValueSLP)
    : undefined;

  const end = endDate
    ? formatDate(safelyParsedDate(endDate), FormatDateType.CalorificValueSLP)
    : undefined;
  try {
    if (customerType === 'slp') {
      if (!(start && end && reason)) {
        return undefined;
      }

      return (
        await getJson<EngCalorificValueCalculatorAnwser>(
          `${urlPath}/${reason}/${start}/${end}/`
        )
      ).value;
    } else if (customerType === 'rlm') {
      if (!rlmDate) {
        return undefined;
      }

      return (
        await getJson<EngCalorificValueCalculatorAnwser>(
          `${urlPath}/${rlmDate}/`
        )
      ).value;
    }
  } catch (err) {
    logger.error(err);
  }
}

export async function getRegions(): Promise<City[]> {
  try {
    return await getJson<City[]>(`${enercityEndpoints.calorificValue}/city/`);
  } catch (err) {
    logger.error(err);
    return [];
  }
}

export function generateYearOptions(): SuggestionOption[] {
  const currentYear = new Date().getFullYear();
  const selectableYears = [];

  for (let iter = 0; iter < selectableYearsInPast; iter++) {
    selectableYears.push({
      label: (currentYear - iter).toString(),
      value: (currentYear - iter).toString(),
    });
  }

  return selectableYears;
}

export const getItemByValue = <T extends FormFieldDropdownOption>(
  items: T[],
  selectedValue: string
) => items.find(({ value }) => value === selectedValue);
