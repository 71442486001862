import Button from '../Button/Button';
import EngStageBox from '../EngStageBox/EngStageBox';
import StageBoxText from '../StageBoxText/StageBoxText';
import type { EngStageBoxTextProps } from './types';

const EngStageBoxText = (props: EngStageBoxTextProps) => {
  return (
    <StageBoxText
      {...props}
      StageBoxComponent={EngStageBox}
      ButtonComponent={Button}
    />
  );
};

export default EngStageBoxText;
