import React from 'react';
import type { EngModalProps } from './interfaces';
import Modal from '../Modal/Modal';
import styles from './EngModal.module.scss';

const EngModal = (props: EngModalProps) => {
  return <Modal backdropClassName={styles.backdrop} {...props} />;
};

export default EngModal;
