import useForm from '@/hooks/useForm/useForm';
import Button from '@uikit/components/Button/Button';
import { ButtonAlign, ButtonType } from '@uikit/components/Button/consts';
import { ClickableAction } from '@uikit/components/Clickable/consts';
import { FormFieldType } from '@uikit/components/FormField/consts';
import FormField from '@uikit/components/FormField/FormField';
import FormFieldDropdown from '@uikit/components/FormFieldDropdown/FormFieldDropdown';
import { FormFieldDropdownOption } from '@uikit/components/FormFieldDropdown/interfaces';
import FormFieldRadioGroup from '@uikit/components/FormFieldRadioGroup/FormFieldRadioGroup';
import FormRow from '@uikit/components/FormRow/FormRow';
import HorizontalSeparator from '@uikit/components/HorizontalSeparator/HorizontalSeparator';
import Icon from '@uikit/components/Icon/Icon';
import RichText from '@uikit/components/RichText/RichText';
import Spacer from '@uikit/components/Spacer/Spacer';
import React, { useEffect, useState } from 'react';
import { calorificValueInitialState, monthSelectOptions } from '../config';
import styles from '../EngCalorificValueCalculator.module.scss';
import CalorificValueCustomerTypeModal from '../EngCalorificValueCustomerTypeModal/CalorificValueCustomerTypeModal';
import { generateYearOptions, getItemByValue } from '../helpers';
import { EngCalorificValueCalculatorStep } from '../interfaces';
import schema from './EngCalorificValueCalculatorForm.schema';

const EngCalorificValueCalculatorForm = ({
  onSubmit,
  globalFormState,
  cities,
  modalHeadline,
  modalText,
}: EngCalorificValueCalculatorStep) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectableYears = React.useMemo(() => generateYearOptions(), []);

  const {
    formState,
    getErrorByFieldName,
    isFormValid,
    handleSubmit,
    handleChange,
    handleChangeValue,
    handleBlur,
    setFieldValue,
    setFormState,
  } = useForm(onSubmit, schema, calorificValueInitialState);

  const toggleModal = () => setIsModalOpen((isModalOpen) => !isModalOpen);

  const handleCityChange = (option: FormFieldDropdownOption | null) => {
    handleChangeValue('city')(option?.value);

    const currentCity =
      cities && option && getItemByValue(cities, option.value);

    if (currentCity && !currentCity.hasMoreThanOneArea) {
      return setFieldValue('district', currentCity.districts[0]);
    }

    setFieldValue('district', undefined, true);
  };

  const handleDistrictChange = (option: FormFieldDropdownOption | null) => {
    const curentCity = cities && getItemByValue(cities, formState.city);
    const currentDistrict =
      option &&
      curentCity &&
      getItemByValue(curentCity.districts, option.value);
    handleChangeValue('district')(currentDistrict);
  };

  const dropDownHandleBlur = (fieldname: string) => () => handleBlur(fieldname);

  const handleCustomerTypeChange = (
    element: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleChangeValue(element.target.name)(element.target.value);
    cleanUpDateValues();
  };

  const cleanUpDateValues = () => {
    setFieldValue('startDate', undefined, true);
    setFieldValue('endDate', undefined, true);
    setFieldValue('periodMonth', undefined, true);
    setFieldValue('periodYear', undefined, true);
  };

  useEffect(() => {
    if (Object.keys(globalFormState).length === 0) {
      setFormState(calorificValueInitialState);
    } else {
      setFormState(globalFormState);
    }
  }, [globalFormState, setFormState]);

  if (!cities?.length) {
    return (
      <RichText>
        <p>
          Die Brennwertermittlung ist aktuell nicht verfügbar, versuchen Sie es
          später erneut.
        </p>
      </RichText>
    );
  }

  const cityOptions = cities.map(({ value, label }) => ({ value, label }));

  const currentCity = getItemByValue(cities, formState.city);
  const districtOptions =
    currentCity?.districts.map(({ value, label }) => ({
      value,
      label,
    })) ?? null;

  return (
    <form onSubmit={handleSubmit}>
      {isModalOpen && (
        <CalorificValueCustomerTypeModal
          modalHeadline={modalHeadline}
          modalText={modalText}
          onClose={toggleModal}
        />
      )}
      <FormRow
        columnMarginSize={{ default: 1.5, md: 3 }}
        bottomMarginSize={{ default: 0 }}
      >
        <div>
          <div className={styles.topLabel}>{'Abrechnungsort'}</div>
          <FormRow bottomMarginSize={{ default: 1 }}>
            <FormFieldDropdown
              label={'Gemeinde'}
              name={'city'}
              value={formState['city'] ?? ''}
              options={cityOptions}
              nativeOptions={cityOptions}
              errorMessage={getErrorByFieldName('city')}
              onChange={handleCityChange}
              onBlur={dropDownHandleBlur('city')}
            />
          </FormRow>

          {currentCity?.hasMoreThanOneArea && !!districtOptions?.length && (
            <FormRow bottomMarginSize={{ default: 1.5, sm: 2 }}>
              <FormFieldDropdown
                label={'Stadtteil'}
                name={'district'}
                value={formState['district']?.value ?? null}
                options={districtOptions}
                nativeOptions={districtOptions}
                onChange={handleDistrictChange}
                errorMessage={getErrorByFieldName('district')}
                onBlur={dropDownHandleBlur('district')}
              />
            </FormRow>
          )}
        </div>
        <div>
          <div className={styles.topLabel}>
            {'Kundentyp '}
            <Icon
              variant={'user/information'}
              size="iconSize20"
              className={styles.infoIcon}
              onClick={toggleModal}
            />
          </div>
          <FormRow>
            <FormFieldRadioGroup
              name={'customerType'}
              value={formState['customerType'] || ''}
              items={[
                { label: 'Standardlastprofilkunde (SLP)', value: 'slp' },
                { label: 'Lastganggemessener Kunde (RLM)', value: 'rlm' },
              ]}
              onChange={handleCustomerTypeChange}
              errorMessage={getErrorByFieldName('customerType')}
            />
          </FormRow>
        </div>
      </FormRow>
      <Spacer defaultSize={1} md={1} />
      {formState['city'] && formState['district'] && (
        <div>
          <HorizontalSeparator color={'surrogat'} margin={'none'} />
          <Spacer defaultSize={1.5} md={3} />
          {formState['customerType'] === 'slp' ? (
            <>
              <FormRow
                columnMarginSize={{ default: 1.5, md: 3 }}
                bottomMarginSize={{ default: 1.5, sm: 1 }}
              >
                <div>
                  <div className={styles.topLabel}>{'Abrechnungszeitraum'}</div>
                  <FormRow bottomMarginSize={{ default: 1 }}>
                    <FormField
                      name={'startDate'}
                      label="Abrechnungsbeginn"
                      value={formState['startDate'] || ''}
                      type={FormFieldType.Date}
                      onChange={handleChange}
                      id={'startDate'}
                      onBlur={handleBlur}
                      errorMessageHasRelativePosition
                      errorMessage={getErrorByFieldName('startDate')}
                    />
                  </FormRow>

                  <FormRow bottomMarginSize={{ default: 0, sm: 2 }}>
                    <FormField
                      name={'endDate'}
                      type={FormFieldType.Date}
                      label="Abrechnungsende"
                      value={formState['endDate'] || ''}
                      onChange={handleChange}
                      id={'endDate'}
                      onBlur={handleBlur}
                      errorMessage={getErrorByFieldName('endDate')}
                    />
                  </FormRow>
                </div>
                <div>
                  <div className={styles.topLabel}>{'Anlass'}</div>
                  <FormRow columnMarginSize={{ default: 1.5, md: 3 }}>
                    <FormFieldRadioGroup
                      name={'reason'}
                      value={formState['reason'] || ''}
                      items={[
                        { label: 'Ablesung', value: 'cycle' },
                        {
                          label: 'Lieferantenwechsel / Auszugsablesung',
                          value: 'reading',
                        },
                      ]}
                      onChange={handleChange}
                      errorMessage={getErrorByFieldName('reason')}
                    />
                  </FormRow>
                </div>
              </FormRow>
            </>
          ) : (
            <>
              <FormRow columnMarginSize={{ default: 1.5, md: 3 }}>
                <div>
                  <div className={styles.topLabel}>{'Abrechnungszeitraum'}</div>
                  <FormRow columnMarginSize={{ default: 1.5, md: 3 }}>
                    <FormFieldDropdown
                      name={'periodYear'}
                      label={'Jahr'}
                      value={formState['periodYear']?.value || ''}
                      options={selectableYears}
                      nativeOptions={selectableYears}
                      onChange={handleChangeValue('periodYear')}
                      errorMessage={getErrorByFieldName('periodYear')}
                      onBlur={dropDownHandleBlur('periodYear')}
                    />
                    <FormFieldDropdown
                      label={'Monat'}
                      name={'periodMonth'}
                      value={formState['periodMonth']?.value || ''}
                      options={monthSelectOptions}
                      nativeOptions={monthSelectOptions}
                      onChange={handleChangeValue('periodMonth')}
                      errorMessage={getErrorByFieldName('periodMonth')}
                      onBlur={dropDownHandleBlur('periodMonth')}
                    />
                  </FormRow>
                </div>
              </FormRow>
            </>
          )}
        </div>
      )}
      <div>
        <Button
          type={ButtonType.Submit}
          actionType={ClickableAction.Custom}
          inactive={!isFormValid}
          align={ButtonAlign.Center}
          onClick={() => void 0}
        >
          Brennwert ermitteln
        </Button>
      </div>
    </form>
  );
};

export default EngCalorificValueCalculatorForm;
