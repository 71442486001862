import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Headline from '@uikit/components/Headline/Headline';
import { HeadlineLevel } from '@uikit/components/Headline/consts';
import ModalCloseIcon from '@uikit/components/Modal/ModalCloseIcon/ModalCloseIcon';
import ModalContent from '@uikit/components/Modal/ModalContent';
import RichText from '@uikit/components/RichText/RichText';
import Spacer from '@uikit/components/Spacer/Spacer';
import type { CalorificValueCustomerTypeModalProps } from './interfaces';

const CalorificValueCustomerTypeModal = ({
  onClose,
  modalHeadline,
  modalText,
  ...props
}: CalorificValueCustomerTypeModalProps) => {
  return (
    <ModalPortal
      id="customerTypeModal"
      variant="shade"
      disableCloseIcon
      contentCentered
      show
      {...props}
    >
      <ModalContent>
        <ModalCloseIcon onClick={onClose} />
        <div>
          <Spacer defaultSize={1} />
          <Headline level={HeadlineLevel.Three}>
            {modalHeadline
              ? modalHeadline
              : 'Welcher Kunden&shy;typ trifft auf Sie zu?'}
          </Headline>
          <Spacer defaultSize={2} />
          {modalText ? (
            <RichText dangerouslySetInnerHTML={{ __html: modalText ?? '' }} />
          ) : (
            <RichText>
              <p>
                <strong>SLP = Standard&shy;lastprofil&shy;kunde</strong> <br />
                Hierzu zählen in der Regel alle Objekte mit einer
                Jahres&shy;arbeit von{' '}
                <strong>weniger als 1.500.000 kWh.</strong>
              </p>
              <p>
                <strong>
                  RLM = Registrierende lastgang&shy;gemessene Kunden
                </strong>
                <br />
                Hierzu zählen in der Regel Objekte mit einer Jahres&shy;arbeit
                von <strong>mehr als 1.500.000 kWh.</strong> Diese haben eine
                spezielle Messung vor Ort, welche stündlich die übernommene
                Arbeitsmenge speichert und von der Ferne auslesbar ist.
              </p>
              <p>
                <strong>SLP - Anlass</strong> <br />
                Wenn das vorherige Ablese&shy;ergebnis nicht aus dem
                Lieferanten&shy;wechsel- beziehungs&shy;weise
                Einzugs&shy;prozess erfolgt ist, dann wird &quot;Ablesung&quot;
                gewählt. Der Punkt &quot;Lieferanten&shy;wechsel /
                Auszugs&shy;ablesung&quot; wird ausgewählt, wenn der Brennwert
                für den Lieferanten&shy;wechsel oder Auszug ermittelt werden
                soll.
              </p>
            </RichText>
          )}
        </div>
      </ModalContent>
    </ModalPortal>
  );
};

export default CalorificValueCustomerTypeModal;
