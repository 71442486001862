import { SuggestionOption } from '../FormFieldSuggestion/interfaces';
import { EngCalorificValueCalculatorStateForm } from './interfaces';
import EngCalorificValueCalculatorForm from './Steps/EngCalorificValueCalculatorForm';
import EngCalorificValueCalculatorResult from './Steps/EngCalorificValueCalculatorResult';

export const calorificValueInitialState: EngCalorificValueCalculatorStateForm =
  {
    city: undefined,
    district: undefined,
    customerType: 'slp',
    reason: 'cycle',
    startDate: undefined,
    endDate: undefined,
  };

export const EngCalorificValueCalculatorSteps = [
  EngCalorificValueCalculatorForm,
  EngCalorificValueCalculatorResult,
];

export const monthSelectOptions: SuggestionOption[] = [
  { label: 'Januar', value: '01' },
  { label: 'Februar', value: '02' },
  { label: 'März', value: '03' },
  { label: 'April', value: '04' },
  { label: 'Mai', value: '05' },
  { label: 'Juni', value: '06' },
  { label: 'Juli', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'Oktober', value: '10' },
  { label: 'November', value: '11' },
  { label: 'Dezember', value: '12' },
];

export const selectableYearsInPast: number = 6;
